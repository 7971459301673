import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import {
  EventBoostersForm,
  EventInformationForm,
} from '../models/event-form.models';
import { isAfter, isBefore, isValid, parse } from 'date-fns';
import { PasswordChangeFormGroup } from '../models/form.models';

export function passwordMatchValidator() {
  return (control: AbstractControl): ValidationErrors | null => {
    const form = control as FormGroup<PasswordChangeFormGroup>;
    const controls = form.controls;
    const password = controls && (controls.password.value as string);
    const confirmPassword =
      controls && (controls.passwordRepeat.value as string);

    const eitherOrBothPasswordsEmpty = !password || !confirmPassword;

    if (eitherOrBothPasswordsEmpty) {
      return null;
    }

    const passwordsMatch = password === confirmPassword;

    return passwordsMatch ? null : { passwordMismatch: true };
  };
}

export function validateDate(
  control: AbstractControl,
): { dateInvalid: true } | null {
  if (!isValid(parse(control.value, 'yyyy-MM-dd', new Date()))) {
    return { dateInvalid: true };
  }
  return null;
}

export function validateZip(control: AbstractControl) {
  if (control.value && control.value.id < 1) {
    return { zipInvalid: true };
  }
  return null;
}

export function validateRegion(control: AbstractControl) {
  if (control.value && control.value.id < 1) {
    return { regionInvalid: true };
  }
  return null;
}

export function validateChosenValue(
  control: AbstractControl,
): { notChosen: true } | null {
  if (control.value === 0) {
    return { notChosen: true };
  }
  return null;
}

export function validateTextAreaRequired(
  control: AbstractControl,
): { empty: true } | null {
  if (control.value.replace(/\s/g, '') === '') {
    return { empty: true };
  }
  return null;
}

export function requiredIfEnabledValidator(control: AbstractControl) {
  if (control && control.disabled) {
    return null;
  } else {
    const value = control.value;
    return value == null || value === '' ? { required: true } : null;
  }
}

export function flyerValidator() {
  return (control: AbstractControl): ValidationErrors | null => {
    const form = control as FormGroup<EventBoostersForm>;
    const formControls = form.controls;
    const flyerSelected =
      formControls &&
      (formControls.boosters.value?.includes('flyer') as boolean);
    const flyerUploaded =
      formControls && (formControls.flyerUploaded.value as boolean);

    if (!flyerSelected) {
      return null;
    }

    if (flyerSelected && !flyerUploaded) {
      return { flyerCheck: true };
    } else {
      return null;
    }
  };
}

export function endDateNotBeforeStartDate() {
  return (control: AbstractControl): ValidationErrors | null => {
    const form = control as FormGroup<EventInformationForm>;
    const formControls = form.controls;
    const startDate = formControls && (formControls.startDate.value as string);
    const endDate = formControls && (formControls.endDate.value as string);
    const startTime = formControls && (formControls.startTime.value as string);
    const endTime = formControls && (formControls.endTime.value as string);

    if (!startTime || !endTime) {
      return null;
    }

    if (
      isBefore(
        new Date(`${endDate} ${endTime}`),
        new Date(`${startDate} ${startTime}`),
      )
    ) {
      return { endDateNotBeforeStartDate: true };
    } else {
      return null;
    }
  };
}

export function startDateNotAfterEndDate() {
  return (control: AbstractControl): ValidationErrors | null => {
    const form = control as FormGroup<EventInformationForm>;
    const formControls = form.controls;
    const startDate = formControls && (formControls.startDate.value as string);
    const endDate = formControls && (formControls.endDate.value as string);
    const startTime = formControls && (formControls.startTime.value as string);
    const endTime = formControls && (formControls.endTime.value as string);

    if (!startTime || !endTime) {
      return null;
    }

    if (
      isAfter(
        new Date(`${startDate} ${startTime}`),
        new Date(`${endDate} ${endTime}`),
      )
    ) {
      return { startDateNotAfterEndDate: true };
    } else {
      return null;
    }
  };
}
